import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { UserMenu } from "./components/UserMenu";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error("UserMenu Error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong.</div>;
    }
    return this.props.children;
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const userMenuContainer = document.getElementById("user-menu");
  console.log("User menu container found:", !!userMenuContainer);

  if (userMenuContainer) {
    try {
      console.log("User menu data:", {
        email: userMenuContainer.dataset.email,
        profileUrl: userMenuContainer.dataset.profileUrl,
        logoutUrl: userMenuContainer.dataset.logoutUrl,
        csrfToken: userMenuContainer.dataset.csrfToken,
        isOrgAdmin: userMenuContainer.dataset.isOrgAdmin === "true",
      });

      const root = createRoot(userMenuContainer);
      root.render(
        <StrictMode>
          <ErrorBoundary>
            <UserMenu
              userEmail={userMenuContainer.dataset.email}
              profileUrl={userMenuContainer.dataset.profileUrl}
              logoutUrl={userMenuContainer.dataset.logoutUrl}
              csrfToken={userMenuContainer.dataset.csrfToken}
              isOrgAdmin={userMenuContainer.dataset.isOrgAdmin === "true"}
            />
          </ErrorBoundary>
        </StrictMode>,
      );
    } catch (error) {
      console.error("User menu initialization error:", error);
    }
  }
});
