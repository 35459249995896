import React from "react";
import { User, LogOut, Building2 } from "lucide-react";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";

export function UserMenu({
  userEmail,
  profileUrl,
  logoutUrl,
  csrfToken,
  isOrgAdmin,
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="focus-visible:ring-0 focus:outline-none"
        >
          <span className="flex items-center space-x-2">
            <i className="bi bi-person-circle"></i>
            <span className="max-w-[200px] truncate">{userEmail}</span>
            <i className="bi bi-chevron-down text-xs"></i>
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="border-card">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <a href={profileUrl} className="w-full">
              <User className="mr-2 h-4 w-4" />
              Profile
            </a>
          </DropdownMenuItem>
          {isOrgAdmin && (
            <DropdownMenuItem asChild>
              <a href="/dashboard/org-manage" className="w-full">
                <Building2 className="mr-2 h-4 w-4" />
                Manage Organization
              </a>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem asChild>
            <form method="post" action={logoutUrl} className="w-full">
              <input
                type="hidden"
                name="csrfmiddlewaretoken"
                value={csrfToken}
              />
              <button
                type="submit"
                className="flex w-full items-center text-destructive"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Log Out
              </button>
            </form>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
